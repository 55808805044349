import { createApp } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
import { createPinia } from 'pinia';
import { OktaAuth } from '@okta/okta-auth-js';
import OktaVue, { navigationGuard } from '@okta/okta-vue';
import NProgress from 'nprogress';
import ImplicitCallback from '@/components/ImplicitCallback.vue';
import getFeedbackService from '@/services/getFeedback';

import App from './App.vue';
import routes from '~pages';

import '@unocss/reset/normalize.css';
import 'uno.css';

const pinia = createPinia();

const oktaAuth = new OktaAuth({
    issuer: import.meta.env.VITE_OKTA_ISSUER,
    clientId: import.meta.env.VITE_OKTA_CLIENT_ID,
    redirectUri: `${window.location.origin}/implicit/callback`,
    pkce: false,
    scopes: ['openid', 'profile', 'email', 'offline_access'],
    responseMode: 'fragment',
    tokenManager: {
        storage: 'sessionStorage',
    },
});
const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        {
            path: '/implicit/callback',
            component: ImplicitCallback,
        },
        ...routes,
    ],
});

router.beforeEach((to, from) => {
    navigationGuard(to);
    useUserStore().loadUser();
    if (to.path !== from.path) {
        NProgress.start();
    }
});
router.afterEach(() => {
    NProgress.done();
    getFeedbackService.notifyRouterNavigation();
});

const app = createApp(App);
app.use(pinia);
app.use(router);
app.use(OktaVue, { oktaAuth });
app.mount('#app');

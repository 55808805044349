<template>
    <div
        class="flex"
        xs="flex-col justify-center items-stretch gap-4"
        md="flex-row justify-between items-center gap-0"
    >
        <div xs="mx-2" md="mx-0">
            <div class="text-kilo text-black">Mon historique</div>
            <div class="text-gray-600 capitalize">
                {{ selectedMonth?.label }} {{ selectedYear?.value }}
            </div>
        </div>

        <div class="flex gap-2" xs="justify-center mx-2" md="justify-end mx-0">
            <CustomSelect v-model="selectedMonth" label="Mois de paie" :items="months" />
            <CustomSelect v-model="selectedYear" label="Année" :items="years" />
        </div>
    </div>
</template>

<script setup lang="ts">
import { Info, DateTime } from 'luxon';

// TYPES
interface CalendarValue {
    label: string;
    value: number | string;
}

// STRUCTURAL
const emit = defineEmits(['update-year-month-filter']);

// DATA
const EVP_STARTING_YEAR = 2022;
const previousDateTime = DateTime.now().minus({ month: 1 });
const previousMonthLong = previousDateTime.monthLong;
const previousYear = previousDateTime.year;

const months = getMonths();
const years: Array<CalendarValue> = getYears(DateTime.now().year);

const selectedMonth = ref(months.find((month) => month.label === previousMonthLong));
const selectedYear = ref(years.find((year) => year.value === previousYear));

// COMPUTED
watchEffect(() => {
    emit('update-year-month-filter', `${selectedYear.value?.value}${selectedMonth.value?.value}`);
});

// METHODS
function getMonths(): Array<CalendarValue> {
    const monthsLabel = Info.months('long', { locale: 'fr' });
    const monthsValue = Info.months('2-digit', { locale: 'fr' });
    return monthsLabel.map((label: string, index: number) => ({
        label,
        value: monthsValue[index],
    }));
}
function getYears(actualYear: number): Array<CalendarValue> {
    const yearsList: Array<CalendarValue> = [];

    for (let i = EVP_STARTING_YEAR; i <= actualYear; i++) {
        yearsList.push({
            label: `${i}`,
            value: i,
        });
    }

    return yearsList;
}
</script>

import { getFeedbackCustomVariableType } from '@/services/types';

function showButton() {
    window.usabilla_live('show');
}
function hideButton() {
    window.usabilla_live('hide');
}
function triggerButton() {
    window.usabilla_live('click');
}
function addCustomVariable(
    variables: getFeedbackCustomVariableType | getFeedbackCustomVariableType[]
) {
    const customVariables = ([] as getFeedbackCustomVariableType[]).concat(variables);
    const parsedCustomVariables = customVariables.map((customVariable) => ({
        [customVariable.key]: customVariable.value,
    }));

    window.usabilla_live('data', {
        custom: { ...parsedCustomVariables },
    });
}
function notifyRouterNavigation() {
    window.usabilla_live('virtualPageView');
}

export default {
    showButton,
    hideButton,
    triggerButton,
    addCustomVariable,
    notifyRouterNavigation,
};

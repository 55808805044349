<template>
    <div>
        <YearMonthFilter @update-year-month-filter="onYearMonthFilterUpdate" />

        <div v-if="!searchError" class="mt-4">
            <WeeklyTimeItem
                v-for="(weeklyTime, index) in filteredWeeklyTimes"
                :key="`${weeklyTime.start_date}${index}`"
                :gid="weeklyTime.employee_gid"
                :status="weeklyTime.status"
                :startDate="weeklyTime.start_date"
                :endDate="weeklyTime.end_date"
                :yearWeek="weeklyTime.year_week"
            />
        </div>

        <NoResultSection v-else :title="searchError.title" :subtitle="searchError.subtitle" />
    </div>
</template>

<script setup lang="ts">
import { DateTime } from 'luxon';
import { WeeklyTimeItemType } from '@/services/types';
import weeklyTimeApi from '@/api/weeklyTime';

const fileName = 'History.Page.vue';

// DATA
const nowYearMonth = getNowYearMonth();
const yearMonthFilter = ref<number | undefined>();
const weeklyTimes: WeeklyTimeItemType[] = await getWeeklyTimes();

// COMPUTED
const filteredWeeklyTimes = computed(() =>
    weeklyTimes.filter((weeklyTime) =>
        yearMonthFilter.value ? weeklyTime.year_month === yearMonthFilter.value : true
    )
);
const searchError = computed(() => {
    if (yearMonthFilter.value && yearMonthFilter.value < 202207) {
        return {
            title: 'Aucun résultat',
            subtitle: "Il n'existe pas d'EVP historisé avant Juillet 2022.",
        };
    } else if (yearMonthFilter.value && nowYearMonth && yearMonthFilter.value > nowYearMonth) {
        return {
            title: 'Aucun résultat',
            subtitle: "Il n'existe actuellement pas d'EVP sur cette période.",
        };
    } else if (filteredWeeklyTimes && filteredWeeklyTimes.value.length) {
        return null;
    } else {
        return {
            title: 'Aucun résultat',
            subtitle: "Nous n'avons pas trouvé de résultats correspondant à votre recherche.",
        };
    }
});

// EVENTS HANDLER
function onYearMonthFilterUpdate(selectedYearMonth: string) {
    yearMonthFilter.value = Number(selectedYearMonth);
}

// METHODS
async function getWeeklyTimes() {
    try {
        return await weeklyTimeApi.getMyWeeklyTimes();
    } catch (error) {
        useErrorStore().showErrorToast({ error, fileName });
    }
}
function getNowYearMonth() {
    const nowDateTime = DateTime.now();
    const nowMonth = nowDateTime.month > 9 ? nowDateTime.month : `0${nowDateTime.month}`;
    return Number(`${nowDateTime.year}${nowMonth}`);
}
</script>

import { Route } from '@/services/types';

const routesConstant: {
    HISTORY: Route;
} = {
    HISTORY: {
        path: '/',
        title: 'Historique',
    },
};

export const LANDING_PAGE = routesConstant.HISTORY;

export default routesConstant;

<template>
    <div v-if="errorStore.fatalError" class="flex flex-col items-center justify-center">
        <h1 class="font-bold">({{ errorStore.fatalError.title }})</h1>
        <div class="text-deca">
            {{ errorStore.fatalError.message }}
        </div>

        <button
            v-if="errorStore.fatalError.solution"
            class="btn-secondary mt-2.5"
            @click="errorStore.fatalError!.solution!.action()"
        >
            {{ errorStore.fatalError.solution.title }}
        </button>
    </div>
</template>

<script setup lang="ts">
const errorStore = useErrorStore();
</script>

<template>
    <div
        ref="customSelectRef"
        class="flex flex-col justify-start gap-1"
        md="flex-none"
        xs="flex-auto"
    >
        <label class="text-gray-600 text-deci">{{ props.label }}</label>
        <div class="relative">
            <button
                class="relative font-sans-serif flex items-center justify-between h-10 min-w-40 w-full px-1 py-0.5 b-1 b-gray-500 bg-white text-left text-gray-800 text-base cursor-pointer"
                xs="flex-auto"
                un-hover="bg-gray-200 b-blue"
                un-focus="ring-2"
                @click="toggleMenu()"
            >
                {{ props.modelValue?.label }}
                <i v-show="isItemsDisplayed" class="i-material-symbols:keyboard-arrow-up" />
                <i v-show="!isItemsDisplayed" class="i-material-symbols:keyboard-arrow-down" />
            </button>

            <ul
                v-show="isItemsDisplayed"
                class="absolute z-10 max-h-xs w-full mt-0.5 overflow-auto bg-white m-0 px-0 py-1 ring-black ring-1 ring-opacity-10 list-none"
            >
                <li
                    v-for="(item, index) in props.items"
                    :key="`${item.value}${index}`"
                    class="relative py-1 px-1 cursor-pointer"
                    un-hover="bg-gray-200 b-l-2 border-blue"
                    :class="{ 'active-item': item.value === props.modelValue?.value }"
                    @click="onItemSelect(item)"
                >
                    {{ item.label }}
                </li>
            </ul>
        </div>
    </div>
</template>

<script setup lang="ts">
// TYPES
type Item = {
    label: string;
    value: any;
};
interface Props {
    label: string;
    items: Item[];
    modelValue?: Item;
}

// STRUCTURAL
const props = defineProps<Props>();
const emit = defineEmits(['update:modelValue']);

// DATA
const customSelectRef = ref<HTMLInputElement | null>(null);
const isItemsDisplayed = ref(false);

// HOOKS
onMounted(() => {
    document.addEventListener('click', onClick);
});
onUnmounted(() => {
    document.removeEventListener('click', onClick);
});

// EVENTS
function onItemSelect(selectedItem: Item) {
    emit('update:modelValue', selectedItem);
    closeMenu();
}
function onClick(event: Event) {
    if (
        customSelectRef.value &&
        !customSelectRef.value.contains(event.target as HTMLInputElement)
    ) {
        closeMenu();
    }
}

// METHODS
function toggleMenu() {
    isItemsDisplayed.value = !isItemsDisplayed.value;
}
function closeMenu() {
    isItemsDisplayed.value = false;
}
</script>

<style scoped>
.active-item {
    @apply bg-blue-200 b-l-2 border-blue;
}
</style>

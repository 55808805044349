import { acceptHMRUpdate, defineStore } from 'pinia';
import breakpoints from '@/constants/breakpoints';

export const useScreenStore = defineStore('screen', () => {
    const screenSize = ref(0);

    const isScreenXS = computed(() => screenSize.value >= breakpoints.XS);
    const isScreenSM = computed(() => screenSize.value >= breakpoints.SM);
    const isScreenMD = computed(() => screenSize.value >= breakpoints.MD);
    const isScreenLG = computed(() => screenSize.value >= breakpoints.LG);
    const isScreenXL = computed(() => screenSize.value >= breakpoints.XL);

    function setScreenSize(size: number) {
        screenSize.value = size;
    }

    return {
        screenSize,

        isScreenXS,
        isScreenSM,
        isScreenMD,
        isScreenLG,
        isScreenXL,

        setScreenSize,
    };
});

if (import.meta.hot) import.meta.hot.accept(acceptHMRUpdate(useScreenStore, import.meta.hot));

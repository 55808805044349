<template>
    <div
        v-if="validatedDateTime"
        class="flex items-center justify-between b-b-1 b-b-gray-300 bg-gray-100 px-2 py-1"
    >
        <div>{{ label }} le {{ validatedDateTime.toFormat(DATE_FORMAT) }}</div>

        <button
            class="flex items-center b-1 b-blue bg-gray-100 px-2 py-1 cursor-pointer"
            hover="bg-blue-lighter"
            @click="getDocument()"
        >
            <i
                class="i-material-symbols:visibility-rounded text-blue text-hecto mr-1 inline-block"
            />
            <span class="text-deci text-blue">Consulter</span>
        </button>
    </div>
</template>

<script setup lang="ts">
import documentApi from '@/api/document';
import { StatusType } from '@/services/types';
import { DateTime } from 'luxon';
import { DATE_FORMAT } from '@/constants/dateFormat';
import pdfOpener from '@/services/pdfOpener';

interface Props {
    gid: string;
    yearWeek: number;
    status: StatusType;
    validatedDate?: string;
}

// STRUCTURAL
const props = defineProps<Props>();
const fileName = 'WeeklyTimeItemDetails.Component.vue';

// COMPUTED
const validatedDateTime = computed(() =>
    props.validatedDate ? DateTime.fromISO(props.validatedDate) : undefined
);
const label = computed(() => {
    switch (props.status) {
        case 'employee':
            return 'EVP envoyé pour validation';
        case 'manager':
            return 'EVP validé par le manager';
        case 'rh':
            return 'EVP validé par le service RH';
    }
});

// METHODS
async function getDocument() {
    try {
        const document = await documentApi.getDocument(props.gid, props.yearWeek, props.status);
        pdfOpener.openPdf(document);
    } catch (error) {
        useErrorStore().showErrorToast({ error, fileName });
    }
}
</script>

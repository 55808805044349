import { gdtClient } from '@/api/client';
import { getApiError } from '@/services/errors/apiErrorHelper';
import errorMessages from '@/constants/errorMessages';

const fileName = 'weeklyTime.api.ts';

async function getMyWeeklyTimes() {
    try {
        const response = await gdtClient.get('employees/me/evps');
        return response.data.items;
    } catch (error) {
        return Promise.reject(getApiError(error, errorMessages.MY_WEEKLY_TIMES, fileName));
    }
}

async function getWeeklyTimeDetails(gid: string, year: number, week: number) {
    try {
        const response = await gdtClient.get(
            `employees/${gid}/evps/years/${year}/weeks/${week}/details`
        );
        return response.data.items;
    } catch (error) {
        return Promise.reject(getApiError(error, errorMessages.WEEKLY_TIME_DETAILS, fileName));
    }
}

export default {
    getMyWeeklyTimes,
    getWeeklyTimeDetails,
};

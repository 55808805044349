/**
 * Open a new PDF created from the given base64 document
 * @param doc: Base64 PDF document
 */
function openPdf(doc: string) {
    const pdfBlob = new Blob([doc], { type: 'application/pdf' });
    const url = URL.createObjectURL(pdfBlob);

    setTimeout(() => window.open(url, '_blank'), 0);
}

export default {
    openPdf,
};

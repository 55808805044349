<template>
    <div
        class="flex flex-col gap-1 b-b-1 b-b-gray-300 p-3"
        hover="bg-gray-100 cursor-pointer"
        @click="loadWeeklyTimeItemDetails()"
    >
        <div class="flex">
            <div class="flex items-center gap-3 flex-1">
                <div xs="hidden" sm="block">
                    <Knob :percentage="knobPercentage">
                        <i
                            class="i-material-symbols:text-snippet-outline text-hecto text-gray-600 text-center inline-block"
                        />
                    </Knob>
                </div>

                <div class="flex flex-col gap-1">
                    <div>Semaine {{ startDateTime.weekNumber }}</div>
                    <div class="text-gray-600" xs="text-deci" sm="text-base">
                        du {{ startDateTime.toFormat(DATE_FORMAT) }} au
                        {{ endDateTime.toFormat(DATE_FORMAT) }}
                    </div>
                </div>
            </div>

            <div class="flex items-center gap-1">
                <div xs="hidden" sm="block">
                    <StatusTag :status="props.status" />
                </div>

                <i
                    v-if="isLoading"
                    class="i-material-symbols:autorenew animate-spin text-kilo inline-block text-gray-600"
                />
                <template v-else>
                    <i
                        v-show="isDetailsDisplayed"
                        class="i-material-symbols:keyboard-arrow-up text-kilo inline-block text-gray-600"
                    />
                    <i
                        v-show="!isDetailsDisplayed"
                        class="i-material-symbols:keyboard-arrow-down text-kilo inline-block text-gray-600"
                    />
                </template>
            </div>
        </div>

        <div class="flex items-center" xs="block" sm="hidden">
            <StatusTag :status="props.status" />
        </div>
    </div>

    <div v-if="isDetailsDisplayed && WeeklyTimeDetails && WeeklyTimeDetails.length">
        <WeeklyTimeItemDetails
            v-for="(weeklyTimeItemDetail, index) in WeeklyTimeDetails"
            :key="`${weeklyTimeItemDetail?.validated_date}${index}`"
            :gid="props.gid"
            :yearWeek="props.yearWeek"
            :status="weeklyTimeItemDetail.status"
            :validatedDate="weeklyTimeItemDetail.validated_date"
        />
    </div>
</template>

<script setup lang="ts">
import { StatusType, WeeklyTimeItemDetailsType } from '@/services/types';
import weeklyTimeApi from '@/api/weeklyTime';
import { DateTime } from 'luxon';
import { DATE_FORMAT } from '@/constants/dateFormat';

interface Props {
    gid: string;
    status: StatusType;
    startDate: string;
    endDate: string;
    yearWeek: number;
}

// STRUCTURAL
const props = defineProps<Props>();
const fileName = 'WeeklyTimeItem.Component.vue';

// DATA
const WeeklyTimeDetails = ref<WeeklyTimeItemDetailsType[]>();
const isDetailsDisplayed = ref(false);
const isLoading = ref(false);

// COMPUTED
const startDateTime = computed(() => DateTime.fromISO(props.startDate));
const endDateTime = computed(() => DateTime.fromISO(props.endDate));
const knobPercentage = computed(() => {
    switch (props.status) {
        case 'employee':
            return 33;
        case 'manager':
            return 66;
        case 'rh':
            return 100;
    }
});

// METHODS
async function loadWeeklyTimeItemDetails() {
    if (WeeklyTimeDetails.value && WeeklyTimeDetails.value.length) {
        isDetailsDisplayed.value = !isDetailsDisplayed.value;
    } else {
        try {
            isLoading.value = true;
            WeeklyTimeDetails.value = await weeklyTimeApi.getWeeklyTimeDetails(
                props.gid,
                startDateTime.value.year,
                startDateTime.value.weekNumber
            );
            isLoading.value = false;
            isDetailsDisplayed.value = true;
        } catch (error) {
            isDetailsDisplayed.value = false;
            useErrorStore().showErrorToast({ error, fileName });
        }
    }
}
</script>

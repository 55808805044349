export const XS = 0;
export const SM = 576;
export const MD = 768;
export const LG = 992;
export const XL = 1200;

export default {
    XS,
    SM,
    MD,
    LG,
    XL,
};

<template>
    <div class="flex flex-col items-center justify-center">
        <h1 class="font-bold">Veuillez patienter, vous allez bientôt être redirigé...</h1>
        <div class="text-deca">Si ce n'est pas le cas, veuillez rafraîchir votre page</div>
        <div class="text-deca">ou</div>

        <button class="btn-secondary mt-2.5" @click="retry()">Cliquez ici</button>
    </div>
</template>

<script setup lang="ts">
import { useAuth } from '@okta/okta-vue';
import EngieError from '@/services/errors/EngieError.model';
import errorMessages from '@/constants/errorMessages';
import { LANDING_PAGE } from '@/constants/routes';
import { FatalErrorSolutionType } from '@/services/types';

const fileName = 'ImplicitCallbackPage.vue';
const auth = useAuth();
const errorStore = useErrorStore();
const router = useRouter();

onBeforeMount(() => manageRedirection());

async function retry() {
    await manageRedirection();
    goToLandingPage();
}
async function manageRedirection() {
    try {
        await auth.handleLoginRedirect();
    } catch (error) {
        const authentError = new EngieError({
            fileName,
            title: errorMessages.OKTA.TITLE,
            message: errorMessages.OKTA.MESSAGE.toParamString(error),
            stack: `Erreur d'authentification: ${error}`,
        });
        errorStore.addFatalError({
            fileName,
            error: authentError,
            solution: emptyCache,
        });
    }
}
const emptyCache: FatalErrorSolutionType = {
    title: 'Réessayer',
    action: () => {
        localStorage.removeItem('okta-cache-storage');
        localStorage.removeItem('okta-token-storage');
        sessionStorage.removeItem('okta-token-storage');
        sessionStorage.removeItem('okta-cache-storage');

        if (errorStore.fatalError) {
            errorStore.removeFatalError(errorStore.fatalError.id);
        }

        goToLandingPage();
    },
};

function goToLandingPage() {
    router.push(LANDING_PAGE.path);
}
</script>

String.prototype.toParamString = function (...params: any) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let parameterizedString = this;

    params.forEach((param: any) => {
        parameterizedString = parameterizedString.replace(/{.*?}/, param);
    });

    return String(parameterizedString);
};

export default {
    // MESSAGES
    MY_WEEKLY_TIMES: "Une erreur est survenue lors de la récupération de votre liste d'EVP.",
    WEEKLY_TIME_DETAILS: "Une erreur est survenue lors de la récupération du détail d'EVP.",
    DOCUMENT_DOWNLOAD: 'Une erreur est survenue lors du téléchargement du document.',

    // TECHNICAL
    OKTA: {
        TITLE: 'Une erreur technique est survenue.',
        MESSAGE: "Vous pouvez contacter helpy et signaler l'erreur : {error}.",
    },
    UNKNOWN: {
        TITLE: 'Erreur inconnue',
        MESSAGE: 'Une erreur inconnue est survenue.',
    },
    NETWORK: {
        TITLE: 'Problème réseau',
        MESSAGE: 'Vérifiez que vous êtes bien connecté.',
    },
    HTTP: {
        _401: {
            TITLE: 'Utilisateur non reconnu',
            MESSAGE: 'Veuillez réessayer ou vérifiez vos droits d’accès à eTime.',
        },
        _403: 'Accès non autorisé',
        _404: 'Ressource introuvable',
        _409: 'Conflit',
        _500: 'Problème serveur',
    },
};

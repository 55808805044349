import { EngieColorsType } from '@/services/types';

export const engieColors: EngieColorsType = {
    primary: '#007acd',
    success: '#418448',
    danger: '#db3835',
    warning: '#ff8c47',
    white: '#ffffff',
    black: '#192229',
    yellow: '#ffeaa1',
    'yellow-lighter': '#ffeaa1',
    'yellow-darker': '#a4853e',
    orange: '#ffbf8e',
    'orange-lighter': '#ffbf8e',
    'orange-darker': '#bd5a21',
    red: '#ffbdb0',
    'red-lighter': '#ffbdb0',
    'red-darker': '#aa2424',
    pink: '#ffb8d6',
    'pink-lighter': '#ffb8d6',
    'pink-darker': '#81475f',
    purple: '#d6c6e9',
    'purple-lighter': '#d6c6e9',
    'purple-darker': '#4d3a62',
    ultramarine: '#c4cbe5',
    'ultramarine-lighter': '#c4cbe5',
    'ultramarine-darker': '#44549b',
    teal: '#88dad8',
    'teal-lighter': '#88dad8',
    'teal-darker': '#0a6361',
    green: '#a2daa3',
    'green-200': '#D8F0D7',
    'green-lighter': '#a2daa3',
    'green-darker': '#316336',
    blue: '#007acd',
    'blue-200': '#E3EBFB',
    'blue-logo': '#00aaff',
    'blue-bolt': '#007acd',
    'blue-darker': '#005aa0',
    'blue-lighter': '#b6cdf8',
    'blue-gradient': '#23D2B5',
    gray: '#768c9b',
    'gray-100': '#f3f5f6',
    'gray-200': '#e6eaed',
    'gray-300': '#c5ced5',
    'gray-400': '#9dadb8',
    'gray-500': '#768c9b',
    'gray-600': '#607a8c',
    'gray-700': '#435c6e',
    'gray-800': '#314452',
    'gray-900': '#1f2c34',
    purpleLavender: '#6d4797',
    spaceUltramarine: '#17255f',
    franchPink: '#f1468d',
    tealGreen: '#0a9994',
    saffronYellow: '#f2c665',
    energySolutions: '#e18554',
    network: '#278cbc',
    thermalSupply: '#4bb0b9',
    renewable: '#67ae6e',
};

export default engieColors;

import axios, { AxiosRequestConfig, AxiosInstance } from 'axios';
import axiosRetry, { IAxiosRetryConfig } from 'axios-retry';
import { ConcurrencyManager } from 'axios-concurrency';
import { useAuth } from '@okta/okta-vue';

const DEFAULT_RETRIES = 2;
const DEFAULT_MAX_CONCURRENT_REQUESTS = 50;
const DEFAULT_RETRY_DELAY_FUNCTION = (retryCount: number) => 1000 * retryCount;

const defaultRequestHandler = async (request: any) => {
    const auth = useAuth();

    if (
        request.baseURL.includes(import.meta.env.VITE_GDT_BASE_URL) ||
        request.baseURL.includes(import.meta.env.VITE_EMEDIA_BASE_URL)
    ) {
        const idToken = await auth.getIdToken();
        if (idToken) {
            request.headers.Authorization = idToken;
        }
    }
    return request;
};
type interceptorType = {
    requestHandler?: (request: any) => Promise<any>;
    requestErrorHandler?: (request: any) => Promise<any>;
    responseSuccessHandler?: (response: any) => Promise<any>;
    responseErrorHandler?: (response: any) => Promise<any>;
};
class AxiosApiBuilder {
    private axiosConfig: AxiosRequestConfig;
    private interceptors: interceptorType[];
    private axiosRetryConfig: IAxiosRetryConfig;
    private maxConcurrentRequests: number;

    constructor(baseURL: string) {
        this.axiosConfig = {
            baseURL,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        };
        this.interceptors = [
            {
                requestHandler: defaultRequestHandler,
            },
        ];
        this.axiosRetryConfig = {
            retries: DEFAULT_RETRIES,
            retryDelay: DEFAULT_RETRY_DELAY_FUNCTION,
        };
        this.maxConcurrentRequests = DEFAULT_MAX_CONCURRENT_REQUESTS;
    }

    build() {
        const newAxiosClient = axios.create(this.axiosConfig);
        axiosRetry(newAxiosClient, this.axiosRetryConfig);
        ConcurrencyManager(newAxiosClient, this.maxConcurrentRequests);

        this.interceptors.forEach(
            ({
                requestHandler,
                requestErrorHandler,
                responseSuccessHandler,
                responseErrorHandler,
            }) => {
                if (requestHandler || requestErrorHandler) {
                    newAxiosClient.interceptors.request.use(requestHandler, requestErrorHandler);
                }
                if (responseSuccessHandler || responseErrorHandler) {
                    newAxiosClient.interceptors.response.use(
                        responseSuccessHandler,
                        responseErrorHandler
                    );
                }
            }
        );
        return newAxiosClient;
    }
}

let gdtClientInstance: AxiosInstance;
let eMediaClientInstance: AxiosInstance;

function getGdtClient() {
    if (!gdtClientInstance) {
        gdtClientInstance = new AxiosApiBuilder(import.meta.env.VITE_GDT_BASE_URL).build();
    }
    return gdtClientInstance;
}
function getEMediaClient() {
    if (!eMediaClientInstance) {
        eMediaClientInstance = new AxiosApiBuilder(import.meta.env.VITE_EMEDIA_BASE_URL).build();
    }
    return eMediaClientInstance;
}

export const gdtClient = getGdtClient();
export const eMediaClient = getEMediaClient();

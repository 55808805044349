import { acceptHMRUpdate, defineStore } from 'pinia';
import { useAuth } from '@okta/okta-vue';

export const useUserStore = defineStore('user', () => {
    const user = ref({
        gid: '',
        name: 'Freddy Winter',
        mail: 'test.com',
    });

    const isUserLoaded = computed(() => user.value.gid);

    async function loadUser() {
        if (!user.value.gid) {
            const auth = useAuth();
            const isAuthenticated = await auth.isAuthenticated();

            if (isAuthenticated) {
                const userInfos = await auth.getUser();
                if (userInfos) {
                    user.value = {
                        gid: userInfos.preferred_username || '',
                        name: userInfos.name || '',
                        mail: userInfos.email || '',
                    };
                }
            }
        }
    }

    return {
        user,
        isUserLoaded,
        loadUser,
    };
});

if (import.meta.hot) import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot));

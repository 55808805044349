import { eMediaClient } from '@/api/client';
import { getApiError } from '@/services/errors/apiErrorHelper';
import errorMessages from '@/constants/errorMessages';
import { StatusType } from '@/services/types';

const fileName = 'document.api.ts';

async function getDocument(gid: string, yearWeek: number, status: StatusType) {
    try {
        const response = await eMediaClient.get('document/evp/download', {
            params: {
                employee_gaia_id: gid,
                year_week: yearWeek,
                status: status,
            },
        });
        return response.data;
    } catch (error) {
        return Promise.reject(getApiError(error, errorMessages.DOCUMENT_DOWNLOAD, fileName));
    }
}

export default {
    getDocument,
};

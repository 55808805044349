<template>
    <div class="min-h-full font-sans-serif bg-white">
        <DefaultHeader />

        <Toast v-if="toastStore.toast.value" />

        <main class="min-h-full h-100% mx-a mt-4 text-black" xs="max-w-100%" md="max-w-80%">
            <FatalErrorViewer v-if="errorStore.hasFatalError" />

            <RouterView v-else v-slot="{ Component }">
                <template v-if="Component">
                    <Transition mode="out-in">
                        <KeepAlive :max="10">
                            <Suspense>
                                <component :is="Component"></component>

                                <template #fallback>
                                    <LoadingSection />
                                </template>
                            </Suspense>
                        </KeepAlive>
                    </Transition>
                </template>
            </RouterView>
        </main>
    </div>
</template>

<script setup lang="ts">
import { initializeLightningjs } from '@/services/js/getFeebackProvidedScript';
const errorStore = useErrorStore();
const toastStore = useToastStore();
const screenStore = useScreenStore();

onBeforeMount(async () => {
    onResize();
    nextTick(() => {
        window.addEventListener('resize', onResize);
    });
    initializeGetFeedBack();
});

function initializeGetFeedBack() {
    const getFeedBackButtonId = screenStore.isScreenMD
        ? import.meta.env.VITE_GETFEEDBACK_BUTTON_WEB
        : import.meta.env.VITE_GETFEEDBACK_BUTTON_MOB;

    initializeLightningjs();
    window.usabilla_live = lightningjs.require(
        'usabilla_live',
        `//w.usabilla.com/${getFeedBackButtonId}.js`
    );
}

function onResize() {
    const actualSize = window.innerWidth;
    if (actualSize !== screenStore.screenSize) {
        screenStore.setScreenSize(actualSize);
    }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');
body {
    margin: 0;
}

.v-enter-active,
.v-leave-active {
    transition: opacity 0.2s ease-in-out;
}
.v-enter-from,
.v-leave-to {
    opacity: 0;
}

#nprogress {
    @apply pointer-events-none;
}

#nprogress .bar {
    @apply bg-green-darker op-75 fixed z-1031 top-0 left-0 w-100% h-2px;
}

.usabilla_scroller_area {
    top: 10% !important;
}
</style>

<template>
    <div class="flex relative items-center justify-center w-fit h-fit">
        <svg :class="{ 'animate-spin': props.infinite }" :height="props.size" :width="props.size">
            <circle
                :stroke-width="strokeWidth"
                :stroke="engieColors[props.bgColor]"
                fill="transparent"
                :r="normalizedRadius"
                :cx="radius"
                :cy="radius"
            />
            <circle
                class="progress-ring__circle"
                :class="[props.infinite ? 'infinite' : 'normal']"
                :stroke-width="strokeWidth"
                :stroke="engieColors[props.color]"
                fill="transparent"
                :stroke-dasharray="`${circumference} ${circumference}`"
                :stroke-dashoffset="strokeDashoffset"
                :r="normalizedRadius"
                :cx="radius"
                :cy="radius"
                :style="styleObject"
            />
        </svg>

        <div class="absolute"><slot></slot></div>
    </div>
</template>

<script setup lang="ts">
import engieColors from '@/constants/colors';
import { EngieColorNamesType } from '@/services/types';

// TYPES
interface Props {
    percentage?: number;
    size?: number;
    width?: number;
    color?: EngieColorNamesType;
    bgColor?: EngieColorNamesType;
    infinite?: boolean;
}

// STRUCTURAL
const props = withDefaults(defineProps<Props>(), {
    percentage: 30,
    size: 64,
    width: 25,
    color: 'blue',
    bgColor: 'gray-200',
    infinite: false,
});

// DATA
const progress = ref(0);

// HOOKS
onBeforeMount(() => {
    if (props.infinite) {
        progress.value = 99;
    } else {
        progress.value = props.percentage;
    }
});

// COMPUTED
const radius = computed(() => props.size / 2);
const strokeWidth = computed(() => (radius.value * props.width) / 100);
const normalizedRadius = computed(() => radius.value - strokeWidth.value);

const circumference = computed(() => normalizedRadius.value * 2 * Math.PI);
const strokeDashoffset = computed(
    () => circumference.value - (progress.value / 100) * circumference.value
);

const styleObject = computed(() => ({
    '--circumference-value': circumference.value,
    '--stroke-dash-offset': strokeDashoffset.value,
}));
</script>

<style scoped lang="scss">
@keyframes growProgressBar {
    0% {
        stroke-dashoffset: var(--circumference-value);
    }
    100% {
        stroke-dashoffset: var(--stroke-dash-offset);
    }
}
.progress-ring__circle {
    transform: rotate(-90deg);
    transform-origin: 50% 50%;

    &.infinite {
        animation: growProgressBar 2s infinite forwards;
    }
    &.normal {
        animation: growProgressBar 2s 1 forwards;
    }
}
</style>

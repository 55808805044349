<template>
    <header>
        <div
            class="flex justify-between items-center py-3 border-b border-gray-300"
            xs="px-2"
            md="px-4"
        >
            <img class="cursor-pointer" src="@/assets/etime-logo.svg" @click="goToLandingPage()" />

            <div class="flex items-center gap-1">
                <i class="i-material-symbols:android-contacts text-hecto" />
                <span v-if="userStore.isUserLoaded" xs="hidden" sm="flex">
                    {{ userStore.user.name }}
                </span>
            </div>
        </div>
    </header>
</template>

<script setup lang="ts">
import { LANDING_PAGE } from '@/constants/routes';

const userStore = useUserStore();
const router = useRouter();

function goToLandingPage() {
    router.push(LANDING_PAGE.path);
}
</script>
